import * as React from 'react'
import CallToAction from '../components/call-to-action/call-to-action'
import Layout from '../components/layout/layout'
import LatestPosts from '../components/latest-posts/latest-posts'
// import GettingStartedPosts from '../components/getting-started-posts/getting-started-posts'

const IndexPage = () => {

  return (
    <Layout pageTitle='Calorie Planning' showNav={true}>
      <CallToAction />
      <LatestPosts />

      {/* I can incorporate Getting Started section later if needed. 
          Or I can create a Popular Posts section instead. 
          The first popular post could kick off a getting started sequence. */}
      
      {/* <GettingStartedPosts /> */}

    </Layout>
  )
}

export default IndexPage