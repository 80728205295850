import * as React from 'react';
import { Link } from 'gatsby';
import { 
    Container,
    Heading,
    CallToActionButton,
} from './call-to-action.style';

const CallToAction = () => {
    
    return ( 
        <Container>
            {/* <Heading>Create Your Calorie Plan Today!</Heading> */}
            <Heading>Calorie Planning Starts With Good Food!</Heading>
            <Link to="/cheatsheet">
                <CallToActionButton primary>Download the FREE Cheat Sheet</CallToActionButton>
            </Link>
        </Container>
     );
}
 
export default CallToAction;