import styled from '@emotion/styled';
import { Button } from '../button/button';

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.background};
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Heading = styled.h1`
    color: ${({ theme }) => theme.colors.white};
    padding: 0 1rem;
`

export const CallToActionButton = styled(Button)`
    font-size: 1.5rem;
    margin: 0 1rem;
    
    @media screen and (max-width: 650px) {
        max-width: 250px;
        font-size: 1.25rem;
    }
`