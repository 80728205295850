import * as React from 'react';
import { useBlogPostData } from '../../hooks/use-blog-posts-data';
import BlogPostGrid from '../blog-post-grid/blog-post-grid';

const LatestPosts = () => {

    const blogPostsArray = useBlogPostData();
    const filteredPosts = blogPostsArray.filter((_, index) => index < 9)

    return (
        <BlogPostGrid filteredPosts={filteredPosts} heading="Latest Posts"/>
     );
}
 
export default LatestPosts;